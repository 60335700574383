import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  apiMetadatasGetAll,
  apiMetadatasGetQuery,
} from 'services/MetadatasService'

const getMetadatasQuery = createAsyncThunk(
  'shared/supplier/getMetadatas',
  async (data) => {
    const response = await apiMetadatasGetQuery(data)
    return response.data
  },
)

export const getMetadatasClientTypes = createAsyncThunk(
  'shared/supplier/getMetadatasClientTypes',
  async (data) => {
    const response = await apiMetadatasGetQuery('client_types')
    return response.data
  },
)

export const getMetadatas = createAsyncThunk(
  'shared/supplier/getMetadatas',
  async (data) => {
    const response = await apiMetadatasGetAll()
    return response.data
  },
)

export const initialState = {
  loading: false,
  data: {
    client_types: [],
    suppliers: [],
  },
}

export const metadatasSlice = createSlice({
  name: 'shared/metadatas',
  initialState,
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload
    },
  },
  extraReducers: {
    [getMetadatasClientTypes.fulfilled]: (state, action) => {
      state.data.client_types = action.payload
    },
    [getMetadatas.fulfilled]: (state, action) => {
      state.data = action.payload
      state.loading = false
    },
    [getMetadatas.pending]: (state, action) => {
      state.loading = true
    },
  },
})

export const {} = metadatasSlice.actions

export default metadatasSlice.reducer
