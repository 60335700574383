import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  apiCreateBrandMenu,
  apiDeleteBrandMenu,
  apiGetBrandMenu,
  apiGetBrandMenuDetails,
  apiGetBrandMenus,
  apiPutBrandMenu,
} from 'services/BrandMenuService'
import { apiGetCompanyBrandsMenus } from 'services/CompanyBrandsService'

export const getCompanyBrandsMenus = createAsyncThunk(
  'shared/brand-menus/getCompanyBrandsMenus',
  async (data) => {
    const response = await apiGetCompanyBrandsMenus(data)
    return response.data
  },
)

export const getBrandMenus = createAsyncThunk(
  'shared/supplier/getBrandMenus',
  async (data) => {
    const response = await apiGetBrandMenus(data)
    return response.data
  },
)

export const getBrandMenu = createAsyncThunk(
  'shared/supplier/getBrandMenu',
  async (data) => {
    const response = await apiGetBrandMenu(data)
    return response.data
  },
)

export const getBrandMenuDetail = createAsyncThunk(
  'shared/supplier/getBrandMenu',
  async (data) => {
    const response = await apiGetBrandMenuDetails(data)
    return response.data
  },
)

export const createBrandMenu = async (data) => {
  const response = await apiCreateBrandMenu(data)
  return response.data
}

export const updateBrandMenu = async (data) => {
  const response = await apiPutBrandMenu(data)
  return response.data
}

export const deleteBrandMenu = createAsyncThunk(
  'shared/supplier/deleteBrandMenu',
  async (data) => {
    const response = await apiDeleteBrandMenu(data)
    return response.data
  },
)

export const brandMenusSlice = createSlice({
  name: 'shared/brand-menus',
  initialState: {
    brandsMenu: {
      loading: false,
      data: [],
    },
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true
      state.token = action.payload
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false
      state.token = ''
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
  extraReducers: {
    [getCompanyBrandsMenus.fulfilled]: (state, action) => {
      state.brandsMenu.data = action.payload
      state.brandsMenu.loading = false
    },
    [getCompanyBrandsMenus.pending]: (state, action) => {
      state.brandsMenu.loading = true
    },
  },
})

export const {} = brandMenusSlice.actions

export default brandMenusSlice.reducer
