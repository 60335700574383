import ApiService from './ApiService'

export async function apiGetCrmDashboardData(data) {
  return ApiService.fetchData({
    url: '/crm/dashboard',
    method: 'get',
    data,
  })
}

export async function apiGetOrders(params) {
  return ApiService.fetchData({
    url: '/orders',
    method: 'get',
    params: params,
  })
}

export async function apiGetStatsOrdersByWeek(params) {
  return ApiService.fetchData({
    url: '/orders/stats-by-week',
    method: 'get',
    params: params,
  })
}

export async function apiGetOrdersQuery(params) {
  return ApiService.fetchData({
    url: '/orders/query',
    method: 'get',
    params: params,
  })
}

export async function apiGetOrder(guid) {
  return ApiService.fetchData({
    url: '/orders/' + guid + '/details',
    method: 'get',
  })
}

export async function apiGetOrdersStatistic(params) {
  return ApiService.fetchData({
    url: '/orders/statistic',
    method: 'get',
    params,
  })
}

export async function apiCreateOrder(data) {
  return ApiService.fetchData({
    url: '/orders',
    method: 'post',
    data,
  })
}
export async function apiPutOrder(data) {
  return ApiService.fetchData({
    url: '/orders/' + data.id + '/update',
    method: 'post',
    data,
  })
}

export async function apiDeleteOrder(data) {
  return ApiService.fetchData({
    url: '/orders/' + data.id + '/delete',
    method: 'post',
    data,
  })
}
