import ApiService from './ApiService'

export async function apiGetCrmDashboardData(data) {
  return ApiService.fetchData({
    url: '/crm/dashboard',
    method: 'get',
    data,
  })
}

export async function apiGetBrandMenus(params) {
  return ApiService.fetchData({
    url: '/brand-menus',
    method: 'get',
    params: params,
  })
}

export async function apiGetBrandMenuDetails(guid) {
  return ApiService.fetchData({
    url: '/brand-menus/' + guid + '/details',
    method: 'get',
  })
}

export async function apiGetBrandMenuCategories(guid) {
  return ApiService.fetchData({
    url: '/brand-menus/' + guid + '/categories',
    method: 'get',
  })
}

export async function apiGetBrandMenuProducts(guid) {
  return ApiService.fetchData({
    url: '/brand-menus/' + guid + '/products',
    method: 'get',
  })
}

export async function apiGetBrandMenusTable(params) {
  return ApiService.fetchData({
    url: '/brand-menus/query',
    method: 'get',
    params: params,
  })
}

export async function apiGetBrandMenu(params) {
  return ApiService.fetchData({
    url: '/brand-menus/' + params.id,
    method: 'get',
    params: params,
  })
}

export async function apiCreateBrandMenu(data) {
  return ApiService.fetchData({
    url: '/brand-menus/',
    method: 'post',
    data,
  })
}

export async function apiPutBrandMenu(data) {
  return ApiService.fetchData({
    url: '/brand-menus/' + data.id + '/update',
    method: 'post',
    data,
  })
}

export async function apiDeleteBrandMenu(data) {
  return ApiService.fetchData({
    url: '/brand-menus/' + data.id + '/delete',
    method: 'delete',
    data,
  })
}

export async function apiUploadMenu(data) {
  return ApiService.fetchData({
    url: '/brand-menus/upload-menu-products',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export async function apiUploadBatchMenu(data) {
  return ApiService.fetchData({
    url: '/brand-menus/upload-menu-products',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export async function apiSynchronizeMenu(data) {
  return ApiService.fetchData({
    url: '/brand-menus/synchronize-menu',
    method: 'post',
    data,
  })
}

export async function apiUploadProductCreator(data) {
  return ApiService.fetchData({
    url: '/brand-menus/upload-product-creator-products',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
