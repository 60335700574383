import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
} from 'react-icons/fa'

export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'

export const PUBLISHING_STATUS = {
  published: {
    id: 1,
    color: 'green-600',
    icon: <FaCheckCircle />,
    translateKey: 'menu.published',
  },
  unpublished: {
    id: 2,
    color: 'amber-500',
    icon: <FaExclamationTriangle />,
    translateKey: 'menu.unpublished_menu',
  },
  publish_failed: {
    id: 3,
    color: 'red-600',
    icon: <FaExclamationCircle />,
    translateKey: 'menu.publish_failed',
  },
}
