import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetCompanyBrandsMenus } from 'services/CompanyBrandsService'

export const getCompanyBrandsMenus = createAsyncThunk(
  'shared/menus/getCompanyBrandsMenus',
  async (data) => {
    const response = await apiGetCompanyBrandsMenus(data)
    return response.data
  },
)

export const menusSlice = createSlice({
  name: 'shared/menu',
  initialState: {
    brandsMenu: {
      loading: false,
      data: [],
    },
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true
      state.token = action.payload
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false
      state.token = ''
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
  extraReducers: {
    [getCompanyBrandsMenus.fulfilled]: (state, action) => {
      state.brandsMenu.data = action.payload
      state.brandsMenu.loading = false
    },
    [getCompanyBrandsMenus.pending]: (state, action) => {
      state.brandsMenu.loading = true
    },
  },
})

export const {} = menusSlice.actions

export default menusSlice.reducer
